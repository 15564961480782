import qs from 'query-string';
import { createContext, useContext, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TourGuide, TourGuideType } from '../../components';
import { useQueryParam } from '../../hooks';
import { ROUTES } from '../../routes';

export const tourContext = createContext({
  initTour: (type: TourGuideType) => {},
  openTour: (type: TourGuideType) => {},
});

const TourProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tourType, setTourType] = useState<TourGuideType | undefined>();

  const hasInitialized = useRef<Record<TourGuideType, boolean>>({
    aerial: false,
    unit: false,
    layer: false,
  });

  const history = useHistory();
  const location = useLocation();

  const filter = useQueryParam('filter');

  return (
    <tourContext.Provider
      value={{
        openTour: (type: TourGuideType) => {
          setTourType(type);
          setIsOpen(true);
        },
        initTour: (type: TourGuideType) => {
          if (!hasInitialized.current[type]) {
            setTourType(type);
            setIsOpen(true);
            hasInitialized.current[type] = true;
          }
        },
      }}
    >
      <TourGuide
        isOpen={isOpen}
        tourType={tourType}
        onRequestClose={() => setIsOpen(false)}
        onPointHotspotStep={() => {
          if (
            !location.pathname.startsWith(ROUTES.UnitTypeA) ||
            (location.pathname.startsWith(ROUTES.UnitTypeA) &&
              filter !== 'essential-fittings')
          ) {
            history.push({
              pathname: ROUTES.UnitTypeA,
              search: `?${qs.stringify({ filter: 'essential-fittings' })}`,
            });
          }
        }}
      />
      {children}
    </tourContext.Provider>
  );
};

export const useTourContext = () => useContext(tourContext);

export default TourProvider;
