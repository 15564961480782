export { default as GardenRoutes } from './Garden';
export * from './Garden';

export { default as UnitRoutes } from './Unit';
export * from './Unit';

export const ROUTES = {
  Garden: '/',
  GardenVirtualTour: '/virtual-tour',
  UnitTypeA: '/type-a',
  UnitTypeAFloorPlan: '/type-a/floor-plan',
  UnitTypeAVirtualTour: '/type-a/virtual-tour',
  UnitTypeA1: '/type-a-1',
  UnitTypeA1FloorPlan: '/type-a-1/floor-plan',
  UnitTypeA1VirtualTour: '/type-a-1/virtual-tour',
  UnitTypeB: '/type-b',
  UnitTypeBFloorPlan: '/type-b/floor-plan',
  UnitTypeBVirtualTour: '/type-b/virtual-tour',
};

export const isFloorPlan = (pathname: string) =>
  pathname.endsWith('/floor-plan');

export const unitRoute = (pathname: string) => {
  if (pathname.startsWith(ROUTES.UnitTypeB)) {
    return ROUTES.UnitTypeB;
  }
  if (pathname.startsWith(ROUTES.UnitTypeA1)) {
    return ROUTES.UnitTypeA1;
  }
  return ROUTES.UnitTypeA;
};

export const unitFloorPlanRoute = (pathname: string) => {
  if (pathname.startsWith(ROUTES.UnitTypeB)) {
    return ROUTES.UnitTypeBFloorPlan;
  }
  if (pathname.startsWith(ROUTES.UnitTypeA1)) {
    return ROUTES.UnitTypeA1FloorPlan;
  }
  return ROUTES.UnitTypeAFloorPlan;
};
