import qs from 'query-string';
import { lazy, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '../';

const UnitFloorPlanPage = lazy(() => import('../../pages/UnitFloorPlan'));
// const UnitDollhousePage = lazy(() => import('../../pages/UnitDollhouse'));

const UnitFloorTypeAPlanPage = () => (
  <UnitFloorPlanPage
    layerRoutes={[
      {
        label: 'Exterior',
        mobileLabel: 'E',
        value: '4',
      },
      {
        label: 'Second Floor',
        mobileLabel: '2',
        value: '3',
      },
      {
        label: 'First Floor',
        mobileLabel: '1',
        value: '2',
      },
      {
        label: 'Ground Floor',
        mobileLabel: 'G',
        value: '1',
      },
    ]}
    keyFrames={19}
    layer4Src={useCallback(
      (i) => `type_a_floor_plan_exterior_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
    layer3Src={useCallback(
      (i) =>
        `type_a_floor_plan_second_floor_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
    layer2Src={useCallback(
      (i) =>
        `type_a_floor_plan_first_floor_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
    layer1Src={useCallback(
      (i) =>
        `type_a_floor_plan_ground_floor_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
  />
);

const UnitFloorTypeA1PlanPage = () => (
  <UnitFloorPlanPage
    layerRoutes={[
      {
        label: 'Exterior',
        mobileLabel: 'E',
        value: '4',
      },
      {
        label: 'Second Floor',
        mobileLabel: '2',
        value: '3',
      },
      {
        label: 'First Floor',
        mobileLabel: '1',
        value: '2',
      },
      {
        label: 'Ground Floor',
        mobileLabel: 'G',
        value: '1',
      },
    ]}
    keyFrames={19}
    layer4Src={useCallback(
      (i) =>
        `type_a_1_floor_plan_exterior_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
    layer3Src={useCallback(
      (i) =>
        `type_a_1_floor_plan_second_floor_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
    layer2Src={useCallback(
      (i) =>
        `type_a_1_floor_plan_first_floor_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
    layer1Src={useCallback(
      (i) =>
        `type_a_1_floor_plan_ground_floor_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
  />
);

const UnitFloorTypeBPlanPage = () => (
  <UnitFloorPlanPage
    layerRoutes={[
      {
        label: 'Exterior',
        mobileLabel: 'E',
        value: '4',
      },
      {
        label: 'First Floor',
        mobileLabel: '1',
        value: '3',
      },
      {
        label: 'Ground Floor',
        mobileLabel: 'G',
        value: '2',
      },
      {
        label: 'Lower Ground Floor',
        mobileLabel: 'LG',
        value: '1',
      },
    ]}
    keyFrames={19}
    layer4Src={useCallback(
      (i) => `type_b_floor_plan_exterior_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
    layer3Src={useCallback(
      (i) =>
        `type_b_floor_plan_first_floor_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
    layer2Src={useCallback(
      (i) =>
        `type_b_floor_plan_ground_floor_${i.toString().padStart(2, '0')}.jpg`,
      []
    )}
    layer1Src={useCallback(
      (i) =>
        `type_b_floor_plan_lower_ground_floor_${i
          .toString()
          .padStart(2, '0')}.jpg`,
      []
    )}
  />
);

const Unit: React.FC = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.UnitTypeAFloorPlan}
      component={UnitFloorTypeAPlanPage}
    />
    <Route
      exact
      path={ROUTES.UnitTypeA1FloorPlan}
      component={UnitFloorTypeA1PlanPage}
    />
    <Route
      exact
      path={ROUTES.UnitTypeBFloorPlan}
      component={UnitFloorTypeBPlanPage}
    />
    {/* TODO: */}
    {/* <Route exact path={ROUTES.UnitTypeA} />
    <Route exact path={ROUTES.UnitTypeA1} />
    <Route exact path={ROUTES.UnitTypeB} /> */}
    <Redirect
      to={{
        pathname: ROUTES.UnitTypeAFloorPlan, // TODO: route to UnitTypeA by default
        search: `?${qs.stringify({ filter: 'exterior' })}`,
      }}
    />
  </Switch>
);

export default Unit;
