import node4_prev3url from '../../assets/pano/garden/images/01__o_preview_3.jpg';
import node4_tile3url from '../../assets/pano/garden/images/01__o_3.jpg';
import node4_prev5url from '../../assets/pano/garden/images/01__o_preview_5.jpg';
import node4_tile5url from '../../assets/pano/garden/images/01__o_5.jpg';
import node4_tile0url from '../../assets/pano/garden/images/01__o_0.jpg';
import node4_tile4url from '../../assets/pano/garden/images/01__o_4.jpg';
import node4_tile2url from '../../assets/pano/garden/images/01__o_2.jpg';
import node4_prev4url from '../../assets/pano/garden/images/01__o_preview_4.jpg';
import node4_tile1url from '../../assets/pano/garden/images/01__o_1.jpg';
import node4_prev0url from '../../assets/pano/garden/images/01__o_preview_0.jpg';
import node4_prev2url from '../../assets/pano/garden/images/01__o_preview_2.jpg';
import node4_prev1url from '../../assets/pano/garden/images/01__o_preview_1.jpg';

import node5_prev3url from '../../assets/pano/garden/images/02__o_preview_3.jpg';
import node5_tile3url from '../../assets/pano/garden/images/02__o_3.jpg';
import node5_prev5url from '../../assets/pano/garden/images/02__o_preview_5.jpg';
import node5_tile5url from '../../assets/pano/garden/images/02__o_5.jpg';
import node5_tile0url from '../../assets/pano/garden/images/02__o_0.jpg';
import node5_tile4url from '../../assets/pano/garden/images/02__o_4.jpg';
import node5_tile2url from '../../assets/pano/garden/images/02__o_2.jpg';
import node5_prev4url from '../../assets/pano/garden/images/02__o_preview_4.jpg';
import node5_tile1url from '../../assets/pano/garden/images/02__o_1.jpg';
import node5_prev0url from '../../assets/pano/garden/images/02__o_preview_0.jpg';
import node5_prev2url from '../../assets/pano/garden/images/02__o_preview_2.jpg';
import node5_prev1url from '../../assets/pano/garden/images/02__o_preview_1.jpg';

import node6_prev3url from '../../assets/pano/garden/images/03__o_preview_3.jpg';
import node6_tile3url from '../../assets/pano/garden/images/03__o_3.jpg';
import node6_prev5url from '../../assets/pano/garden/images/03__o_preview_5.jpg';
import node6_tile5url from '../../assets/pano/garden/images/03__o_5.jpg';
import node6_tile0url from '../../assets/pano/garden/images/03__o_0.jpg';
import node6_tile4url from '../../assets/pano/garden/images/03__o_4.jpg';
import node6_tile2url from '../../assets/pano/garden/images/03__o_2.jpg';
import node6_prev4url from '../../assets/pano/garden/images/03__o_preview_4.jpg';
import node6_tile1url from '../../assets/pano/garden/images/03__o_1.jpg';
import node6_prev0url from '../../assets/pano/garden/images/03__o_preview_0.jpg';
import node6_prev2url from '../../assets/pano/garden/images/03__o_preview_2.jpg';
import node6_prev1url from '../../assets/pano/garden/images/03__o_preview_1.jpg';

import node7_prev3url from '../../assets/pano/garden/images/04__o_preview_3.jpg';
import node7_tile3url from '../../assets/pano/garden/images/04__o_3.jpg';
import node7_prev5url from '../../assets/pano/garden/images/04__o_preview_5.jpg';
import node7_tile5url from '../../assets/pano/garden/images/04__o_5.jpg';
import node7_tile0url from '../../assets/pano/garden/images/04__o_0.jpg';
import node7_tile4url from '../../assets/pano/garden/images/04__o_4.jpg';
import node7_tile2url from '../../assets/pano/garden/images/04__o_2.jpg';
import node7_prev4url from '../../assets/pano/garden/images/04__o_preview_4.jpg';
import node7_tile1url from '../../assets/pano/garden/images/04__o_1.jpg';
import node7_prev0url from '../../assets/pano/garden/images/04__o_preview_0.jpg';
import node7_prev2url from '../../assets/pano/garden/images/04__o_preview_2.jpg';
import node7_prev1url from '../../assets/pano/garden/images/04__o_preview_1.jpg';

import node8_prev3url from '../../assets/pano/garden/images/05_o_preview_3.jpg';
import node8_tile3url from '../../assets/pano/garden/images/05_o_3.jpg';
import node8_prev5url from '../../assets/pano/garden/images/05_o_preview_5.jpg';
import node8_tile5url from '../../assets/pano/garden/images/05_o_5.jpg';
import node8_tile0url from '../../assets/pano/garden/images/05_o_0.jpg';
import node8_tile4url from '../../assets/pano/garden/images/05_o_4.jpg';
import node8_tile2url from '../../assets/pano/garden/images/05_o_2.jpg';
import node8_prev4url from '../../assets/pano/garden/images/05_o_preview_4.jpg';
import node8_tile1url from '../../assets/pano/garden/images/05_o_1.jpg';
import node8_prev0url from '../../assets/pano/garden/images/05_o_preview_0.jpg';
import node8_prev2url from '../../assets/pano/garden/images/05_o_preview_2.jpg';
import node8_prev1url from '../../assets/pano/garden/images/05_o_preview_1.jpg';

import node9_prev3url from '../../assets/pano/garden/images/06__o_preview_3.jpg';
import node9_tile3url from '../../assets/pano/garden/images/06__o_3.jpg';
import node9_prev5url from '../../assets/pano/garden/images/06__o_preview_5.jpg';
import node9_tile5url from '../../assets/pano/garden/images/06__o_5.jpg';
import node9_tile0url from '../../assets/pano/garden/images/06__o_0.jpg';
import node9_tile4url from '../../assets/pano/garden/images/06__o_4.jpg';
import node9_tile2url from '../../assets/pano/garden/images/06__o_2.jpg';
import node9_prev4url from '../../assets/pano/garden/images/06__o_preview_4.jpg';
import node9_tile1url from '../../assets/pano/garden/images/06__o_1.jpg';
import node9_prev0url from '../../assets/pano/garden/images/06__o_preview_0.jpg';
import node9_prev2url from '../../assets/pano/garden/images/06__o_preview_2.jpg';
import node9_prev1url from '../../assets/pano/garden/images/06__o_preview_1.jpg';

import node10_prev3url from '../../assets/pano/garden/images/07__o_preview_3.jpg';
import node10_tile3url from '../../assets/pano/garden/images/07__o_3.jpg';
import node10_prev5url from '../../assets/pano/garden/images/07__o_preview_5.jpg';
import node10_tile5url from '../../assets/pano/garden/images/07__o_5.jpg';
import node10_tile0url from '../../assets/pano/garden/images/07__o_0.jpg';
import node10_tile4url from '../../assets/pano/garden/images/07__o_4.jpg';
import node10_tile2url from '../../assets/pano/garden/images/07__o_2.jpg';
import node10_prev4url from '../../assets/pano/garden/images/07__o_preview_4.jpg';
import node10_tile1url from '../../assets/pano/garden/images/07__o_1.jpg';
import node10_prev0url from '../../assets/pano/garden/images/07__o_preview_0.jpg';
import node10_prev2url from '../../assets/pano/garden/images/07__o_preview_2.jpg';
import node10_prev1url from '../../assets/pano/garden/images/07__o_preview_1.jpg';

const buildConfig = (
  startNode: string
) => `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<tour start="${startNode}" apprev="17253" appversion="6.0.2">
  <panorama id="node4">
    <input prev3url="${node4_prev3url}" tile3url="${node4_tile3url}" prev5url="${node4_prev5url}" levelingroll="0" tile5url="${node4_tile5url}" tile0url="${node4_tile0url}" tile4url="${node4_tile4url}" tile2url="${node4_tile2url}" prev4url="${node4_prev4url}" tilesize="2048" tile1url="${node4_tile1url}" levelingpitch="0" prev0url="${node4_prev0url}" tilescale="1.004883" prev2url="${node4_prev2url}" prev1url="${node4_prev1url}">
      <preview color="0x808080"/>
    </input>
    <view fovmode="0" pannorth="-94.0582">
      <start fov="81.81" projection="4" pan="16.46" tilt="2.68"/>
      <flyin fov="170" projection="9" pan="0" tilt="-90"/>
      <min pan="0" tilt="-90" fovpixel="2"/>
      <max fov="120" fovfisheye="360" pan="360" tilt="90" fovstereographic="270" scaletofit="1"/>
    </view>
    <userdata latitude="" author="" title="Entrance" info="" copyright="" tags="360 spots|park" source="" description="" customnodeid="entrance" comment="" datetime="10/16/2021 11:47 AM" longitude="">
      <mapcoords x_floorplan_percent="83.79629629629629" x="2.356770833333333" y="-1.067708333333333" mapid="ParkMap" y_floorplan_percent="16.4"/>
    </userdata>
    <hotspots wordwrap="1" width="180" height="20">
      <label enabled="1" background="1" textcolor="0x000000" bordercolor="0x000000" backgroundalpha="1" borderradius="1" wordwrap="1" width="180" textalpha="1" borderalpha="1" border="1" height="20" backgroundcolor="0xffffff"/>
      <polystyle handcursor="1" bordercolor="0x0000ff" backgroundalpha="0.2509803921568627" borderalpha="1" mode="0" backgroundcolor="0x0000ff"/>
      <hotspot skinid="ht_node" url="{node5}" title="Nexus Lawn" target="" pan="-357.27" description="" tilt="9.96" id="Entrance"/>
    </hotspots>
    <media/>
    <transition enabled="1" zoomin="0" softedge="0" zoomspeed="2" zoomoutpause="1" blendtime="1" zoomout="0" type="crossdissolve" blendcolor="0x000000" zoomfov="20"/>
    <animation animsequence="Animation01" syncanimationwithvideo="0" useinautorotation="0"/>
    <control dblclickfullscreen="0" sensitivity="8" lockedmouse="0" simulatemass="1" invertwheel="1" lockedwheel="0" lockedkeyboard="0" contextprojections="0" lockedkeyboardzoom="0" hideabout="0" speedwheel="1" contextfullscreen="1" invertcontrol="1" rubberband="0"/>
  </panorama>
  <panorama id="node5">
    <input prev3url="${node5_prev3url}" tile3url="${node5_tile3url}" prev5url="${node5_prev5url}" levelingroll="0" tile5url="${node5_tile5url}" tile0url="${node5_tile0url}" tile4url="${node5_tile4url}" tile2url="${node5_tile2url}" prev4url="${node5_prev4url}" tilesize="2048" tile1url="${node5_tile1url}" levelingpitch="0" prev0url="${node5_prev0url}" tilescale="1.004883" prev2url="${node5_prev2url}" prev1url="${node5_prev1url}">
      <preview color="0x808080"/>
    </input>
    <view fovmode="0" pannorth="-185.702">
      <start fov="70" projection="4" pan="-44.92" tilt="-0.74"/>
      <flyin fov="170" projection="9" pan="0" tilt="-90"/>
      <min pan="0" tilt="-90" fovpixel="2"/>
      <max fov="120" fovfisheye="360" pan="360" tilt="90" fovstereographic="270" scaletofit="1"/>
    </view>
    <userdata latitude="" author="" title="Nexus Lawn" info="" copyright="" tags="360 spots|park" source="" description="" customnodeid="nexus_lawn" comment="" datetime="10/16/2021 11:47 AM" longitude="">
      <mapcoords x_floorplan_percent="44.9074074074074" x="1.263020833333333" y="-1.236979166666667" mapid="ParkMap" y_floorplan_percent="19"/>
    </userdata>
    <hotspots wordwrap="1" width="180" height="20">
      <label enabled="1" background="1" textcolor="0x000000" bordercolor="0x000000" backgroundalpha="1" borderradius="1" wordwrap="1" width="180" textalpha="1" borderalpha="1" border="1" height="20" backgroundcolor="0xffffff"/>
      <polystyle handcursor="1" bordercolor="0x0000ff" backgroundalpha="0.2509803921568627" borderalpha="1" mode="0" backgroundcolor="0x0000ff"/>
      <hotspot skinid="ht_node" url="{node4}" title="Entrance" target="" pan="-266.34" description="" tilt="-0.38" id="Point01"/>
      <hotspot skinid="ht_node" url="{node6}" title="Nexus Lawn 2" target="" pan="-75.1" description="" tilt="4.02" id="Point02"/>
    </hotspots>
    <media/>
    <transition enabled="1" zoomin="0" softedge="0" zoomspeed="2" zoomoutpause="1" blendtime="1" zoomout="0" type="crossdissolve" blendcolor="0x000000" zoomfov="20"/>
    <animation animsequence="Animation01" syncanimationwithvideo="0" useinautorotation="0"/>
    <control dblclickfullscreen="0" sensitivity="8" lockedmouse="0" simulatemass="1" invertwheel="1" lockedwheel="0" lockedkeyboard="0" contextprojections="0" lockedkeyboardzoom="0" hideabout="0" speedwheel="1" contextfullscreen="1" invertcontrol="1" rubberband="0"/>
  </panorama>
  <panorama id="node6">
    <input prev3url="${node6_prev3url}" tile3url="${node6_tile3url}" prev5url="${node6_prev5url}" levelingroll="0" tile5url="${node6_tile5url}" tile0url="${node6_tile0url}" tile4url="${node6_tile4url}" tile2url="${node6_tile2url}" prev4url="${node6_prev4url}" tilesize="2048" tile1url="${node6_tile1url}" levelingpitch="0" prev0url="${node6_prev0url}" tilescale="1.004883" prev2url="${node6_prev2url}" prev1url="${node6_prev1url}">
      <preview color="0x808080"/>
    </input>
    <view fovmode="0" pannorth="-241.586">
      <start fov="91.35" projection="4" pan="0" tilt="0"/>
      <flyin fov="87.04" projection="4" pan="22.41" tilt="-3.28"/>
      <min pan="0" tilt="-90" fovpixel="2"/>
      <max fov="120" fovfisheye="360" pan="360" tilt="90" fovstereographic="270" scaletofit="1"/>
    </view>
    <userdata latitude="" author="" title="Nexus Lawn 2" info="" copyright="" tags="360 spots|park" source="" description="" customnodeid="nexus_lawn2" comment="" datetime="10/16/2021 11:47 AM" longitude="">
      <mapcoords x_floorplan_percent="27.77777777777778" x="0.78125" y="-1.380208333333333" mapid="ParkMap" y_floorplan_percent="21.2"/>
    </userdata>
    <hotspots wordwrap="1" width="180" height="20">
      <label enabled="1" background="1" textcolor="0x000000" bordercolor="0x000000" backgroundalpha="1" borderradius="1" wordwrap="1" width="180" textalpha="1" borderalpha="1" border="1" height="20" backgroundcolor="0xffffff"/>
      <polystyle handcursor="1" bordercolor="0x0000ff" backgroundalpha="0.2509803921568627" borderalpha="1" mode="0" backgroundcolor="0x0000ff"/>
      <hotspot skinid="ht_node" url="{node5}" title="Nexus Lawn" target="" pan="-305.55" description="" tilt="2.25" id="Point01"/>
      <hotspot skinid="ht_node" url="{node7}" title="Type A" target="" pan="-95.58" description="" tilt="2.37" id="Point02"/>
    </hotspots>
    <media/>
    <transition enabled="1" zoomin="0" softedge="0" zoomspeed="2" zoomoutpause="1" blendtime="1" zoomout="0" type="crossdissolve" blendcolor="0x000000" zoomfov="20"/>
    <animation animsequence="Animation01" syncanimationwithvideo="0" useinautorotation="0"/>
    <control dblclickfullscreen="0" sensitivity="8" lockedmouse="0" simulatemass="1" invertwheel="1" lockedwheel="0" lockedkeyboard="0" contextprojections="0" lockedkeyboardzoom="0" hideabout="0" speedwheel="1" contextfullscreen="1" invertcontrol="1" rubberband="0"/>
  </panorama>
  <panorama id="node7">
    <input prev3url="${node7_prev3url}" tile3url="${node7_tile3url}" prev5url="${node7_prev5url}" levelingroll="0" tile5url="${node7_tile5url}" tile0url="${node7_tile0url}" tile4url="${node7_tile4url}" tile2url="${node7_tile2url}" prev4url="${node7_prev4url}" tilesize="2048" tile1url="${node7_tile1url}" levelingpitch="0" prev0url="${node7_prev0url}" tilescale="1.004883" prev2url="${node7_prev2url}" prev1url="${node7_prev1url}">
      <preview color="0x808080"/>
    </input>
    <view fovmode="0" pannorth="90.1831">
      <start fov="88.46" projection="4" pan="-78.32" tilt="-0.92"/>
      <flyin fov="170" projection="9" pan="0" tilt="-90"/>
      <min pan="0" tilt="-90" fovpixel="2"/>
      <max fov="120" fovfisheye="360" pan="360" tilt="90" fovstereographic="270" scaletofit="1"/>
    </view>
    <userdata latitude="" author="" title="Type A" info="" copyright="" tags="360 spots|park" source="" description="" customnodeid="typeA" comment="" datetime="10/16/2021 11:47 AM" longitude="">
      <mapcoords x_floorplan_percent="17.12962962962963" x="0.4817708333333334" y="-1.5234375" mapid="ParkMap" y_floorplan_percent="23.4"/>
    </userdata>
    <hotspots wordwrap="1" width="180" height="20">
      <label enabled="1" background="1" textcolor="0x000000" bordercolor="0x000000" backgroundalpha="1" borderradius="1" wordwrap="1" width="180" textalpha="1" borderalpha="1" border="1" height="20" backgroundcolor="0xffffff"/>
      <polystyle handcursor="1" bordercolor="0x0000ff" backgroundalpha="0.2509803921568627" borderalpha="1" mode="0" backgroundcolor="0x0000ff"/>
      <hotspot skinid="ht_node" url="{node6}" title="Nexus Lawn 2" target="" pan="-316.4" description="" tilt="-2.95" id="Point01"/>
      <hotspot skinid="icon_typeA" url="" title="Type A" target="_blank" pan="-94.84" description="" tilt="-0.48" id="Link_A"/>
      <hotspot skinid="ht_node" url="{node8}" title="Ring Park" target="" pan="-121.77" description="" tilt="9.9" id="Point02"/>
    </hotspots>
    <media/>
    <transition enabled="1" zoomin="0" softedge="0" zoomspeed="2" zoomoutpause="1" blendtime="1" zoomout="0" type="crossdissolve" blendcolor="0x000000" zoomfov="20"/>
    <animation animsequence="Animation01" syncanimationwithvideo="0" useinautorotation="0"/>
    <control dblclickfullscreen="0" sensitivity="8" lockedmouse="0" simulatemass="1" invertwheel="1" lockedwheel="0" lockedkeyboard="0" contextprojections="0" lockedkeyboardzoom="0" hideabout="0" speedwheel="1" contextfullscreen="1" invertcontrol="1" rubberband="0"/>
  </panorama>
  <panorama id="node8">
    <input prev3url="${node8_prev3url}" tile3url="${node8_tile3url}" prev5url="${node8_prev5url}" levelingroll="0" tile5url="${node8_tile5url}" tile0url="${node8_tile0url}" tile4url="${node8_tile4url}" tile2url="${node8_tile2url}" prev4url="${node8_prev4url}" tilesize="2048" tile1url="${node8_tile1url}" levelingpitch="0" prev0url="${node8_prev0url}" tilescale="1.004883" prev2url="${node8_prev2url}" prev1url="${node8_prev1url}">
      <preview color="0x808080"/>
    </input>
    <view fovmode="0" pannorth="-5.35011">
      <start fov="84.01" projection="4" pan="-4.07" tilt="1.58"/>
      <flyin fov="170" projection="9" pan="0" tilt="-90"/>
      <min pan="0" tilt="-90" fovpixel="2"/>
      <max fov="120" fovfisheye="360" pan="360" tilt="90" fovstereographic="270" scaletofit="1"/>
    </view>
    <userdata latitude="" author="" title="Ring Park" info="" copyright="" tags="360 spots|park" source="" description="" customnodeid="ring_park" comment="" datetime="10/16/2021 11:47 AM" longitude="">
      <mapcoords x_floorplan_percent="15.27777777777778" x="0.4296875" y="-2.5" mapid="ParkMap" y_floorplan_percent="38.4"/>
    </userdata>
    <hotspots wordwrap="1" width="180" height="20">
      <label enabled="1" background="1" textcolor="0x000000" bordercolor="0x000000" backgroundalpha="1" borderradius="1" wordwrap="1" width="180" textalpha="1" borderalpha="1" border="1" height="20" backgroundcolor="0xffffff"/>
      <polystyle handcursor="1" bordercolor="0x0000ff" backgroundalpha="0.2509803921568627" borderalpha="1" mode="0" backgroundcolor="0x0000ff"/>
      <hotspot skinid="ht_node" url="{node7}" title="Type A" target="" pan="-340.7" description="" tilt="0.95" id="Point01"/>
      <hotspot skinid="ht_node" url="{node9}" title="Type B &amp; A1 East" target="" pan="-13.59" description="" tilt="0.48" id="road"/>
      <hotspot skinid="icon_typeA1" url="" title="Type A1" target="_blank" pan="-50.36" description="" tilt="-1.77" id="Link_A1"/>
    </hotspots>
    <media/>
    <transition enabled="1" zoomin="0" softedge="0" zoomspeed="2" zoomoutpause="1" blendtime="1" zoomout="0" type="crossdissolve" blendcolor="0x000000" zoomfov="20"/>
    <animation animsequence="Animation01" syncanimationwithvideo="0" useinautorotation="0"/>
    <control dblclickfullscreen="0" sensitivity="8" lockedmouse="0" simulatemass="1" invertwheel="1" lockedwheel="0" lockedkeyboard="0" contextprojections="0" lockedkeyboardzoom="0" hideabout="0" speedwheel="1" contextfullscreen="1" invertcontrol="1" rubberband="0"/>
  </panorama>
  <panorama id="node9">
    <input prev3url="${node9_prev3url}" tile3url="${node9_tile3url}" prev5url="${node9_prev5url}" levelingroll="0" tile5url="${node9_tile5url}" tile0url="${node9_tile0url}" tile4url="${node9_tile4url}" tile2url="${node9_tile2url}" prev4url="${node9_prev4url}" tilesize="2048" tile1url="${node9_tile1url}" levelingpitch="0" prev0url="${node9_prev0url}" tilescale="1.004883" prev2url="${node9_prev2url}" prev1url="${node9_prev1url}">
      <preview color="0x808080"/>
    </input>
    <view fovmode="0" pannorth="43.3378">
      <start fov="79.09" projection="4" pan="-3.16" tilt="-0.2"/>
      <flyin fov="170" projection="9" pan="0" tilt="-90"/>
      <min pan="0" tilt="-90" fovpixel="2"/>
      <max fov="120" fovfisheye="360" pan="360" tilt="90" fovstereographic="270" scaletofit="1"/>
    </view>
    <userdata latitude="" author="" title="Type B &amp; A1 East" info="" copyright="" tags="360 spots|park" source="" description="" customnodeid="typeB_A1_east" comment="" datetime="10/16/2021 11:47 AM" longitude="">
      <mapcoords x_floorplan_percent="17.59259259259259" x="0.4947916666666667" y="-1.901041666666667" mapid="ParkMap" y_floorplan_percent="29.2"/>
    </userdata>
    <hotspots wordwrap="1" width="180" height="20">
      <label enabled="1" background="1" textcolor="0x000000" bordercolor="0x000000" backgroundalpha="1" borderradius="1" wordwrap="1" width="180" textalpha="1" borderalpha="1" border="1" height="20" backgroundcolor="0xffffff"/>
      <polystyle handcursor="1" bordercolor="0x0000ff" backgroundalpha="0.2509803921568627" borderalpha="1" mode="0" backgroundcolor="0x0000ff"/>
      <hotspot skinid="ht_node" url="{node7}" title="Type A" target="" pan="-275.94" description="" tilt="5.56" id="Point01"/>
      <hotspot skinid="ht_node" url="{node8}" title="Ring Park" target="" pan="-129.66" description="" tilt="-3.63" id="Point02"/>
      <hotspot skinid="ht_node" url="{node10}" title="Type B &amp; A1 West" target="" pan="-13.26" description="" tilt="0.34" id="Point03"/>
      <hotspot skinid="icon_typeB" url="" title="Type B" target="_blank" pan="-304.86" description="" tilt="-0.62" id="Link_B"/>
      <hotspot skinid="icon_typeA1" url="" title="Type A1" target="_blank" pan="-85.5" description="" tilt="-1.96" id="Link_A1"/>
    </hotspots>
    <media/>
    <transition enabled="1" zoomin="0" softedge="0" zoomspeed="2" zoomoutpause="1" blendtime="1" zoomout="0" type="crossdissolve" blendcolor="0x000000" zoomfov="20"/>
    <animation animsequence="Animation01" syncanimationwithvideo="0" useinautorotation="0"/>
    <control dblclickfullscreen="0" sensitivity="8" lockedmouse="0" simulatemass="1" invertwheel="1" lockedwheel="0" lockedkeyboard="0" contextprojections="0" lockedkeyboardzoom="0" hideabout="0" speedwheel="1" contextfullscreen="1" invertcontrol="1" rubberband="0"/>
  </panorama>
  <panorama id="node10">
    <input prev3url="${node10_prev3url}" tile3url="${node10_tile3url}" prev5url="${node10_prev5url}" levelingroll="0" tile5url="${node10_tile5url}" tile0url="${node10_tile0url}" tile4url="${node10_tile4url}" tile2url="${node10_tile2url}" prev4url="${node10_prev4url}" tilesize="2048" tile1url="${node10_tile1url}" levelingpitch="0" prev0url="${node10_prev0url}" tilescale="1.004883" prev2url="${node10_prev2url}" prev1url="${node10_prev1url}">
      <preview color="0x808080"/>
    </input>
    <view fovmode="0" pannorth="-102.328">
      <start fov="70" projection="4" pan="0" tilt="0"/>
      <flyin fov="170" projection="9" pan="0" tilt="-90"/>
      <min pan="0" tilt="-90" fovpixel="2"/>
      <max fov="120" fovfisheye="360" pan="360" tilt="90" fovstereographic="270" scaletofit="1"/>
    </view>
    <userdata latitude="" author="" title="Type B &amp; A1 West" info="" copyright="" tags="360 spots|park" source="" description="" customnodeid="typeB_A1_west" comment="" datetime="10/16/2021 11:47 AM" longitude="">
      <mapcoords x_floorplan_percent="79.62962962962963" x="2.239583333333333" y="-1.354166666666667" mapid="ParkMap" y_floorplan_percent="20.8"/>
    </userdata>
    <hotspots wordwrap="1" width="180" height="20">
      <label enabled="1" background="1" textcolor="0x000000" bordercolor="0x000000" backgroundalpha="1" borderradius="1" wordwrap="1" width="180" textalpha="1" borderalpha="1" border="1" height="20" backgroundcolor="0xffffff"/>
      <polystyle handcursor="1" bordercolor="0x0000ff" backgroundalpha="0.2509803921568627" borderalpha="1" mode="0" backgroundcolor="0x0000ff"/>
      <hotspot skinid="ht_node" url="{node9}" title="Type B &amp; A1 East" target="" pan="-2.3" description="" tilt="3.73" id="Point01"/>
      <hotspot skinid="ht_node" url="{node4}" title="Entrance" target="" pan="-111.05" description="" tilt="-3" id="Point02"/>
      <hotspot skinid="ht_node" url="{node5}" title="Nexus Lawn" target="" pan="-71.09" description="" tilt="3.99" id="Point03"/>
      <hotspot skinid="icon_typeB" url="" title="Type B" target="_blank" pan="-26.18" description="" tilt="3.32" id="Link_B"/>
      <hotspot skinid="icon_typeA1" url="" title="Type A1" target="_blank" pan="-292.64" description="" tilt="2.53" id="Link_A1"/>
    </hotspots>
    <media/>
    <transition enabled="1" zoomin="0" softedge="0" zoomspeed="2" zoomoutpause="1" blendtime="1" zoomout="0" type="crossdissolve" blendcolor="0x000000" zoomfov="20"/>
    <animation animsequence="Animation01" syncanimationwithvideo="0" useinautorotation="0"/>
    <control dblclickfullscreen="0" sensitivity="8" lockedmouse="0" simulatemass="1" invertwheel="1" lockedwheel="0" lockedkeyboard="0" contextprojections="0" lockedkeyboardzoom="0" hideabout="0" speedwheel="1" contextfullscreen="1" invertcontrol="1" rubberband="0"/>
  </panorama>
  <masternode>
    <userdata latitude="" author="" title="" info="" copyright="" tags="" source="" description="" customnodeid="" comment="" datetime="" longitude=""/>
  </masternode>
  <map transparent="1" floorplannorth="0" title="Park Map" width="216" tileformat="png" height="500" id="ParkMap" type="file" bgcolor="rgba(255, 255, 255, 0)" zoomlevels="1"/>
</tour>`;

export default buildConfig;
