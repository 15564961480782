import { createTheme } from '@mui/material/styles';

export default createTheme({
  typography: {
    fontFamily: 'nunito-sans',
  },
  palette: {
    primary: {
      main: '#05415b',
    },
    secondary: {
      main: '#cca970',
    },
    background: {
      default: 'white',
    },
  },
});
