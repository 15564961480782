import '../../assets/pano/garden/skin';

import qs from 'query-string';
import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppBar, Button, Toolbar } from '@mui/material';

import { ROUTES } from '../';
import { IconHouse } from '../../components';
import { hrefLinkProps, ORIENTATION_SEQUENCES } from '../../utils';
import buildConfig from './gardenConfig';

const GardenPage = lazy(() => import('../../pages/Garden'));
const UnitPage = lazy(() => import('../../pages/Unit'));
const VirtualTourPage = lazy(() => import('../../pages/VirtualTour'));

const GardenVirtualTour = () => (
  <VirtualTourPage
    baseURL={`${process.env.PUBLIC_URL}/garden-virtual-tour/`}
    containerID="garden-pano-container"
    panoConfigXMLString={(currentNode) => buildConfig(currentNode)}
    defaultNode="entrance"
  >
    {() => (
      <AppBar color="transparent" elevation={0}>
        <Toolbar>
          <Button
            {...hrefLinkProps(
              `${ROUTES.Garden}?${qs.stringify({
                orientation: ORIENTATION_SEQUENCES[0],
              })}`
            )}
            variant="contained"
            startIcon={<IconHouse />}
            color="primary"
          >
            <span>Back</span>
          </Button>
        </Toolbar>
      </AppBar>
    )}
  </VirtualTourPage>
);

const Garden: React.FC = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.GardenVirtualTour}
      component={GardenVirtualTour}
    />
    <Route
      path={[ROUTES.UnitTypeA, ROUTES.UnitTypeA1, ROUTES.UnitTypeB]}
      component={UnitPage}
    />
    <Route exact path={ROUTES.Garden} component={GardenPage} />
    <Redirect
      to={{
        pathname: ROUTES.Garden,
        search: `?${qs.stringify({
          orientation: ORIENTATION_SEQUENCES[0],
        })}`,
      }}
    />
  </Switch>
);

export default Garden;
