import { useRef } from 'react';

import { useDrag } from '@use-gesture/react';

function useDragThresholdDeltaY(
  onDragTop: () => void,
  onDragBottom: () => void,
  threshold = 20
) {
  const deltaThreshold = useRef(0);
  return useDrag(
    ({ delta: [deltaX, deltaY] }) => {
      deltaThreshold.current += deltaY;
      if (Math.abs(deltaThreshold.current) > threshold) {
        if (deltaY < 0) {
          onDragTop();
        } else {
          onDragBottom();
        }
        deltaThreshold.current = 0;
      }
    },
    { mouseOnly: false }
  );
}

export default useDragThresholdDeltaY;
